import React, {useState} from 'react';
import translation from './translation.json';
import './App.css';

function App() {
  const [language,setLanguage] = useState<"HY" | "EN">("HY")

  const currentTranslationLanguage = language.toLocaleLowerCase() as "hy" | "en";
  const translations = translation[currentTranslationLanguage]

  const languageLabel = language === 'HY'? 'EN' : 'HY'

  const handleChangeLanguage = () => {
    setLanguage(languageLabel)
  }

  return (
    <section className="app">
      <div className='wrapper'>
        <header
          className='header'
          onClick={handleChangeLanguage}
        >
          {languageLabel}
        </header>
        <h1 className='title'>{translations.title}</h1>
        <h2 className='subtitle'>{translations.subTitle}</h2>
        <p className='paragraph'>{translations.address}</p>
        <p className='paragraph'>{translations.phone}</p>
      </div>
    </section>
  );
}

export default App;
